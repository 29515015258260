import React, {Component} from 'react';
import '../../../styles/static-web-css/bootstrap.css';
import '../../../styles/static-web-css/style.css';
import luluhk from '../../../static-web-img/luluhk.png';
import {changeLanguage} from "../../../utils/utils";

class PrivacyWebSc extends Component {
    
    componentDidMount () {
        const delay = ms => new Promise(res => setTimeout(res, ms));

        const script = document.createElement("script");
        script.nonce = localStorage.getItem('nonce') || 'default_nonce';
        script.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js";
        script.async = true;
        document.body.appendChild(script);

        const loadJqueryAfter = async () => {
            await delay(1000);
            const script2 = document.createElement("script");
            script2.nonce = localStorage.getItem('nonce') || 'default_nonce';
            script2.src = "https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js";
            script2.async = true;
        
            document.body.appendChild(script2);
    
            /*const script3 = document.createElement("script");
            script3.nonce = localStorage.getItem('nonce') || 'default_nonce';
            script3.src = "https://use.fontawesome.com/releases/v5.13.0/js/all.js";
            script3.async = true;
        
            document.body.appendChild(script3);*/
        };
        
        loadJqueryAfter();
    }

    render() {
        return (
            <div className="static-web-styling">
        <nav className="static-web-styling navbar navbar-expand-lg navbar-light border-bottom border-dark fixed-top" id="topnav">
            <a className="static-web-styling navbar-brand" href="/sc"><img draggable="false" src={luluhk} alt="luluhk"/></a>
            
            <button className="static-web-styling navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse-main">
                <span className="static-web-styling sr-only">Toggle navigation</span>
                <span className="static-web-styling navbar-toggler-icon"></span>
            </button>

            <div className="static-web-styling collapse navbar-collapse" id="navbar-collapse-main">
                <ul className="static-web-styling navbar-nav ml-auto">
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link active" href="/sc/privacy">隐私</a></li>
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link" href="/sc/terms">条款</a></li>
                </ul>
            </div>
        </nav>
        <div className="static-web-styling container">
            <div className="static-web-styling terms text-justify">
                <h1>隐私政策</h1>
                <p>版本：1.0 | 生效日期：2025年3月1日</p>
                <hr/>
                <p>路路香港科技有限公司（English: Lulu HK Technologies Limited）和/或其附属公司和/或关联公司（统称“路路香港”）承诺遵守香港特别行政区（“香港”）法例第486章《个人资料（私隐）条例》的规定。我们将确保员工对用户通过luluhk.com、其子域、商家服务系统、任何其他全部或部分由路路香港开发的网站、其子域、媒体平台或应用程式包括路路香港流动应用程式（个别或统称“渠道”）提交的所有个人资料和数据（简称“个人资料”）遵守最严格的安全和保密标准。在未获相关用户的同意（无论注册与否）（简称“用户”），我们不会向任何人泄露此等个人资料，惟第4条“<strong>资料的披露或传递</strong>”中列出的获授权人士除外。</p>
                <p>我们强烈建议用户仔细阅读本<strong>隐私政策</strong>以便了解我们对于用户在渠道上提供的个人资料的相关政策和实际应用。本<strong>隐私政策</strong>适用于注册和非注册用户，并且当我们认为有必要时可不定期更新、修订、更改和/或修改相关条款内容。</p>
                
                <ol className="static-web-styling ol-h2">
                    <li className="static-web-styling ol-h2-li"><h2>定义</h2>
                        <p>在本<strong>隐私政策</strong>中，除文意另有所指外：</p>
                        <dl>
                            <div>
                                <dt>渠道</dt>
                                <dd>个别或统指luluhk.com、其子域、商家服务系统、任何其他全部或部分由路路香港开发的网站、其子域、媒体平台或应用程式包括路路香港流动应用程式；</dd>
                            </div>
                            <div>
                                <dt>香港</dt>
                                <dd>指香港特别行政区；</dd>
                            </div>
                            <div>
                                <dt>路路香港</dt>
                                <dd>统指路路香港科技有限公司（English: Lulu HK Technologies Limited）和/或其附属公司和/或关联公司；</dd>
                            </div>
                            <div>
                                <dt>材料</dt>
                                <dd>指在渠道上的内容、商家资料、商店资料、产品资料、照片、文本、徽标、图像、图形、连结和/或其他任何资讯和材料；</dd>
                            </div>
                            <div>
                                <dt>会员</dt>
                                <dd>指在渠道上拥有注册账户的个人或实体；</dd>
                            </div>
                            <div>
                                <dt>商家</dt>
                                <dd>指任何使用商家服务的组织和其关联人士；</dd>
                            </div>
                            <div>
                                <dt>个人资料</dt>
                                <dd>指个人资料和数据；</dd>
                            </div>
                            <div>
                                <dt>产品</dt>
                                <dd>指产品和服务；及</dd>
                            </div>
                            <div>
                                <dt>用户</dt>
                                <dd>指使用渠道的个人或实体，包括非注册用户、注册用户和商家。</dd>
                            </div>
                        </dl>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>收集个人资料的目的</h2>
                        <p>在使用渠道的过程中，用户可能会透露或会被要求提供个人资料。虽然用户不必应要求通过渠道提交个人资料，惟若用户拒绝提交其资料，路路香港将可能无法向其提供若干渠道上的服务。我们在渠道中收集个人资料的目的包括但不仅限于以下几点：</p>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">为用户提供服务的日常运作；</li>
                            <li className="static-web-styling ol-li2-li">核实用户身份并向用户提供渠道所供应的服务；</li>
                            <li className="static-web-styling ol-li2-li">为用户提供平台以浏览和分享有关商家的产品；</li>
                            <li className="static-web-styling ol-li2-li">辨认在渠道上发布材料的用户身份；</li>
                            <li className="static-web-styling ol-li2-li">识别浏览过渠道上材料的用户；</li>
                            <li className="static-web-styling ol-li2-li">向用户提供营销和宣传信息；</li>
                            <li className="static-web-styling ol-li2-li">处理及跟进查询、报告、申请和投诉；</li>
                            <li className="static-web-styling ol-li2-li">联络有关用户就用户通过渠道使用路路香港提供的服务而发出的行政通知和通信；</li>
                            <li className="static-web-styling ol-li2-li">为用户设计、发展和改善产品；</li>
                            <li className="static-web-styling ol-li2-li">统计并分析用户使用渠道及服务的情况以供我们内部使用；</li>
                            <li className="static-web-styling ol-li2-li">执行路路香港的<strong>条款和细则</strong>及其他权利；</li>
                            <li className="static-web-styling ol-li2-li">以遵守法律义务；及</li>
                            <li className="static-web-styling ol-li2-li">用于与上述用途有关的任何其他目的。</li>
                        </ol><br/>
                        <p>我们力求只会收集我们认为相关且必要的个人资料，而不会就上述用途收集过量资料。</p>
                        <p>除上述目的以外，我们亦有可能须要使用用户的个人资料作其他目的，就此，我们会征求用户的订明同意。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>个人资料的收集</h2>
                        <p>我们收集和保存有关用户的个人资料，如他/她/它的姓名、用户名和已加密的密码、住址、电邮地址、电话号码、国家/地区、社交媒体和任何其他用户自愿提供的数据。我们亦会偶尔从调查或特别优惠的方式从用户收集额外的个人资料。</p>
                        <p>当用户进入渠道时，我们会自动从用户的电脑或设备收集信息，并记录在cookies和日志档中（详情请参阅第5条“<strong>Cookies和日志档</strong>”）。我们还可能收集有关用户通过渠道进行的活动信息，如浏览的内容、到访的网页、搜索和使用的服务。</p>
                        <p>只有我们授权的员工才会被允许接触用户的个人资料，除了第4条“<strong>资料的披露或传递</strong>”所列出的情况外，我们不会向任何第三方披露此等个人资料。</p>
                        <p>我们不会收集和保存任何标记为本地保存在用户设备上的数据。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>资料的披露或传递</h2>
                        <p>一般来说，我们只将用户的个人资料披露和/或提供予我们内部员工用作列载于第2条“<strong>收集个人资料的目的</strong>”的目的。然而，在下列情况下，我们可能会将其资料传递给第三方：</p>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">在<strong>条款和细则</strong>允许的情况下；</li>
                            <li className="static-web-styling ol-li2-li">在保密原则下，当资料披露和/或提供给任何我们授权的代理人、第三方供应商或外部供应商，以促进渠道所提供的服务；</li>
                            <li className="static-web-styling ol-li2-li">予监管、政府或司法机构以保护和捍卫我们的权利和财产；</li>
                            <li className="static-web-styling ol-li2-li">当我们认为根据适用的法律法规，包括但不限于司法程序、法院命令或渠道上的法律程序，而有必要提供予监管、政府或司法机构；</li>
                            <li className="static-web-styling ol-li2-li">当我们认为有必要以传送路路香港供应的产品、维持和改善渠道的服务；及</li>
                            <li className="static-web-styling ol-li2-li">当我们在任何合并、融资、收购、解散、交易、诉讼、无力偿债、破产或被接管的谈判过程中出售、转让、剥离或披露其全部或部分业务或资产给另一家公司。这第三方将须要采取适当的保密和安全措施。</li>
                        </ol><br/>
                        <p>通过渠道所收集的个人资料可以在路路香港运作的任何国家内转移、储存或处理。一旦使用渠道，用户将被视为同意并授权我们在上述情况下披露和转载他/她/它的个人资料，以及将用户资料（包括材料）转载到其所在以外的其他国家。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Cookies和日志档</h2>
                        <p>Cookies是放置于用户电脑或装置上的小型文字档案，包含一串可单独识别用户浏览器或装置的字元。</p>
                        <p>当用户进入渠道时，我们自动把诸如功能变数名称伺服器地址、用户访问的页面等资料记录在cookies中。我们也自动把诸如互联网协定地址、浏览器类型、互联网服务供应商、进/退页面、作业系统、日期/时间戳记和点击流量等资料记录在日志档中。我们不会把以上自动收集的数据与用户任何的个人身份信息连接。</p>
                        <p>我们一般会使用cookies和日志档来了解渠道的使用方式、评估渠道和宣传信息的受众规模、帮助用户有效地浏览页面、帮助记住用户的偏好设置及优化用户使用渠道和服务的体验。路路香港、路路香港聘请的第三方供应商及服务供应商有可能共同使用路路香港的cookies及第三方cookies来优化用户在网上看到的营销信息，并使营销信息与用户兴趣更加相关。</p>
                        <p>用户可以选择接受或拒绝cookies。大多网络浏览器都会自动接受cookies，但如果有需要，用户可以修改其浏览器的设置以拒绝cookies。不过用户应注意，禁用浏览器上的cookies可能会导致他/她/它无法充分体验渠道的功能。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>第三方网站/媒体平台/应用程式的连结</h2>
                        <p>渠道可能会提供非由我们拥有或管理的第三方网站/媒体平台/应用程式的连结。当用户决定点击渠道上允许用户进入一个第三方网站/媒体平台/应用程式的任何连结，他/她/它的个人资料可能会在这些第三方网站/媒体平台/应用程式中显露和被收集。</p>
                        <p>在此要提醒用户，此类第三方网站/媒体平台/应用程式履行个别和独立的隐私政策，而本<strong>隐私政策</strong>并不会对用户显露于第三方网站/媒体平台/应用程式的个人资料提供保障。我们不会就用户使用该等第三方网站/媒体平台/应用程式承担任何责任或义务。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>安全</h2>
                        <p>我们遵循公认的行业标准和采用适当电子和管理方面的措施以保护用户提交的个人资料免受未经授权的接触、意外丢失或破坏。</p>
                        <p>所有用户的个人资料由路路香港或路路香港授权的第三方之授权人员才可接触，而当这些授权人员接触此类个人资料时，会被指示须要遵守本<strong>隐私政策</strong>的条款。</p>
                        <p>注册用户有责任确保其密码安全和机密。</p>
                        <p>尽管我们致力保护用户的个人资料，没有一种互联网传输或电子储存方法是完全安全的，所以我们亦不能保证其绝对安全。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>数据检阅和更正的要求</h2>
                        <p>用户可提出检阅或更正由路路香港保留的其个人资料的要求，以保持此类信息准确。用户可登录账户维护页面查看、编辑或修改已储存的个人资料或透过列载于第12条“<strong>联络方法</strong>”的途径联络我们的客户服务代表，提出要求。</p>
                        <p>如用户希望检阅或更正他/她/它的个人资料，我们可能要求他/她/它提供某些个人信息以验证他/她/它的身份。某些资料，包括但不限于商业登记证号码，是无法修改的，除非这些资料证明是不准确的。</p>
                        <p>根据相关法律，我们有权就此要求收取合理的费用。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>数据的保留</h2>
                        <p>我们一经取得用户的个人资料，资料将安全地保留在我们的系统中以履行列载于第2条“<strong>收集个人资料的目的</strong>”的目的。根据法律规定，其个人资料将会由路路香港保留，直至用户透过列载于第12条“<strong>联络方法</strong>”的途径联络我们的客户服务代表终止其个人在渠道的会员资格。然而，如我们需要此类数据以履行相关法律义务和/或建立、行使或辩护索赔之用，我们有权保留此类数据。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>隐私政策的变更</h2>
                        <p>当我们认为有必要时，我们有权随时更新、修改、修订或更改本<strong>隐私政策</strong>。我们强烈建议用户定期查看本<strong>隐私政策</strong>。若我们决定变更本<strong>隐私政策</strong>，我们将在此网页和/或其他我们认为合适的地方发布经修订的<strong>隐私政策</strong>。若我们的<strong>隐私政策</strong>有重大更改，我们将在路路香港的网站上、手机应用程序上和/或透过电子邮件通知各用户。</p>
                        <p>任何相关改动会在经修订的<strong>隐私政策</strong>发布在此网页时立即生效。用户于上述改动后继续使用路路香港的渠道和服务即表示接受当时生效经修订的<strong>隐私政策</strong>。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>主导语言</h2>
                        <p>如英文版本与其他语言的版本有任何抵触或不相符之处，概以英文版本为准。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>联络方法</h2>
                        <p>如您对本<strong>隐私政策</strong>有任何疑问，请透过以下途径联络我们的客户服务代表：</p>
                        <address><strong>路路香港科技有限公司</strong><br/>
                        电邮地址：<a href = "mailto: contact@luluhk.com">contact@luluhk.com</a></address>
                    </li>
                </ol>
            </div>
        </div>
        
        <nav className="static-web-styling navbar navbar-expand navbar-light bg-light border-top border-dark fixed-bottom" id="bottomnav">
            <div className="static-web-styling collapse navbar-collapse">
                <ul className="static-web-styling navbar-nav mr-auto">
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/en/privacy" lang="en" hrefLang="en" onClick={()=>changeLanguage("en")}>EN</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/tc/privacy" lang="zh-Hant" hrefLang="zh-Hant" onClick={()=>changeLanguage("zh_Hant")}>繁</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link active" href="/sc/privacy" onClick={()=>changeLanguage("zh_Hans")}>简</a></li>
                </ul>
                <span className="static-web-styling copyright">&copy; 2025 路路香港科技有限公司</span>
            </div>
        </nav>  
            </div>
        )
    }
}

export default PrivacyWebSc;
