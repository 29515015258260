import React, {Component} from 'react';
import '../../../styles/static-web-css/bootstrap.css';
import '../../../styles/static-web-css/style.css';
import luluhk from '../../../static-web-img/luluhk.png';
import {changeLanguage} from "../../../utils/utils";

class PrivacyWebTc extends Component {
    
    componentDidMount () {
        const delay = ms => new Promise(res => setTimeout(res, ms));

        const script = document.createElement("script");
        script.nonce = localStorage.getItem('nonce') || 'default_nonce';
        script.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js";
        script.async = true;
        document.body.appendChild(script);

        const loadJqueryAfter = async () => {
            await delay(1000);
            const script2 = document.createElement("script");
            script2.nonce = localStorage.getItem('nonce') || 'default_nonce';
            script2.src = "https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js";
            script2.async = true;
        
            document.body.appendChild(script2);
    
            /*const script3 = document.createElement("script");
            script3.nonce = localStorage.getItem('nonce') || 'default_nonce';
            script3.src = "https://use.fontawesome.com/releases/v5.13.0/js/all.js";
            script3.async = true;
        
            document.body.appendChild(script3);*/
        };
        
        loadJqueryAfter();
    }

    render() {
        return (
            <div className="static-web-styling">
        <nav className="static-web-styling navbar navbar-expand-lg navbar-light border-bottom border-dark fixed-top" id="topnav">
            <a className="static-web-styling navbar-brand" href="/tc"><img draggable="false" src={luluhk} alt="luluhk"/></a>
            
            <button className="static-web-styling navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse-main">
                <span className="static-web-styling sr-only">Toggle navigation</span>
                <span className="static-web-styling navbar-toggler-icon"></span>
            </button>

            <div className="static-web-styling collapse navbar-collapse" id="navbar-collapse-main">
                <ul className="static-web-styling navbar-nav ml-auto">
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link active" href="/tc/privacy">私隱</a></li>
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link" href="/tc/terms">條款</a></li>
                </ul>
            </div>
        </nav>
        <div className="static-web-styling container">
            <div className="static-web-styling terms text-justify">
                <h1>私隱政策</h1>
                <p>版本：1.0 | 生效日期：2025年3月1日</p>
                <hr/>
                <p>路路香港科技有限公司（English: Lulu HK Technologies Limited）和/或其附屬公司和/或關聯公司（統稱“路路香港”）承諾遵守香港特別行政區（“香港”）法例第486章《個人資料（私隱）條例》的規定。我們將確保員工對用戶通過luluhk.com、其子域、商家服務系統、任何其他全部或部分由路路香港開發的網站、其子域、媒體平台或應用程式包括路路香港流動應用程式（個別或統稱“渠道”）提交的所有個人資料和數據（簡稱“個人資料”）遵守最嚴格的安全和保密標準。在未獲相關用戶的同意（無論註冊與否）（簡稱“用戶”），我們不會向任何人洩露此等個人資料，惟第4條“<strong>資料的披露或傳遞</strong>”中列出的獲授權人士除外。</p>
                <p>我們強烈建議用戶仔細閱讀本<strong>私隱政策</strong>以便瞭解我們對於用戶在渠道上提供的個人資料的相關政策和實際應用。本<strong>私隱政策</strong>適用於註冊和非註冊用戶，並且當我們認為有必要時可不定期更新、修訂、更改和/或修改相關條款內容。</p>
                
                <ol className="static-web-styling ol-h2">
                    <li className="static-web-styling ol-h2-li"><h2>定義</h2>
                        <p>在本<strong>私隱政策</strong>中，除文意另有所指外：</p>
                        <dl>
                            <div>
                                <dt>渠道</dt>
                                <dd>個別或統指luluhk.com、其子域、商家服務系統、任何其他全部或部分由路路香港開發的網站、其子域、媒體平台或應用程式包括路路香港流動應用程式；</dd>
                            </div>
                            <div>
                                <dt>香港</dt>
                                <dd>指香港特別行政區；</dd>
                            </div>
                            <div>
                                <dt>路路香港</dt>
                                <dd>統指路路香港科技有限公司（English: Lulu HK Technologies Limited）和/或其附屬公司和/或關聯公司；</dd>
                            </div>
                            <div>
                                <dt>材料</dt>
                                <dd>指在渠道上的內容、商家資料、商店資料、產品資料、照片、文本、徽標、圖像、圖形、連結和/或其他任何資訊和材料；</dd>
                            </div>
                            <div>
                                <dt>會員</dt>
                                <dd>指在渠道上擁有註冊賬戶的個人或實體；</dd>
                            </div>
                            <div>
                                <dt>商家</dt>
                                <dd>指任何使用商家服務的組織和其關聯人士；</dd>
                            </div>
                            <div>
                                <dt>個人資料</dt>
                                <dd>指個人資料和數據；</dd>
                            </div>
                            <div>
                                <dt>產品</dt>
                                <dd>指產品和服務；及</dd>
                            </div>
                            <div>
                                <dt>用戶</dt>
                                <dd>指使用渠道的個人或實體，包括非註冊用戶、註冊用戶和商家。</dd>
                            </div>
                        </dl>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>收集個人資料的目的</h2>
                        <p>在使用渠道的過程中，用戶可能會透露或會被要求提供個人資料。雖然用戶不必應要求通過渠道提交個人資料，惟若用戶拒絕提交其資料，路路香港將可能無法向其提供若干渠道上的服務。我們在渠道中收集個人資料的目的包括但不僅限於以下幾點：</p>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">為用戶提供服務的日常運作；</li>
                            <li className="static-web-styling ol-li2-li">核實用戶身份並向用戶提供渠道所供應的服務；</li>
                            <li className="static-web-styling ol-li2-li">為用戶提供平台以瀏覽和分享有關商家的產品；</li>
                            <li className="static-web-styling ol-li2-li">辨認在渠道上發佈材料的用戶身份；</li>
                            <li className="static-web-styling ol-li2-li">識別瀏覽過渠道上材料的用戶；</li>
                            <li className="static-web-styling ol-li2-li">向用戶提供營銷和宣傳信息；</li>
                            <li className="static-web-styling ol-li2-li">處理及跟進查詢、報告、申請和投訴；</li>
                            <li className="static-web-styling ol-li2-li">聯絡有關用戶就用戶通過渠道使用路路香港提供的服務而發出的行政通知和通信；</li>
                            <li className="static-web-styling ol-li2-li">為用戶設計、發展和改善產品；</li>
                            <li className="static-web-styling ol-li2-li">統計並分析用戶使用渠道及服務的情況以供我們內部使用；</li>
                            <li className="static-web-styling ol-li2-li">執行路路香港的<strong>條款和細則</strong>及其他權利；</li>
                            <li className="static-web-styling ol-li2-li">以遵守法律義務；及</li>
                            <li className="static-web-styling ol-li2-li">用於與上述用途有關的任何其他目的。</li>
                        </ol><br/>
                        <p>我們力求只會收集我們認為相關且必要的個人資料，而不會就上述用途收集過量資料。</p>
                        <p>除上述目的以外，我們亦有可能須要使用用戶的個人資料作其他目的，就此，我們會徵求用戶的訂明同意。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>個人資料的收集</h2>
                        <p>我們收集和儲存有關用戶的個人資料，如他/她/它的姓名、用戶名稱和已加密的密碼、住址、電郵地址、電話號碼、國家/地區、社交媒體和任何其他用戶自願提供的數據。我們亦會偶爾從調查或特別優惠的方式從用戶收集額外的個人資料。</p>
                        <p>當用戶進入渠道時，我們會自動從用戶的電腦或設備收集信息，並記錄在cookies和日誌檔中（詳情請參閱第5條“<strong>Cookies和日誌檔</strong>”）。我們還可能收集有關用戶通過渠道進行的活動信息，如瀏覽的內容、到訪的網頁、搜索和使用的服務。</p>
                        <p>只有我們授權的員工才會被允許接觸用戶的個人資料，除了第4條“<strong>資料的披露或傳遞</strong>”所列出的情況外，我們不會向任何第三方披露此等個人資料。</p>
                        <p>我們不會收集和儲存任何標記為本地儲存在用戶裝置上的數據。 </p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>資料的披露或傳遞</h2>
                        <p>一般來說，我們只將用戶的個人資料披露和/或提供予我們內部員工用作列載於第2條“<strong>收集個人資料的目的</strong>”的目的。然而，在下列情況下，我們可能會將其資料傳遞給第三方：</p>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">在<strong>條款和細則</strong>允許的情況下；</li>
                            <li className="static-web-styling ol-li2-li">在保密原則下，當資料披露和/或提供給任何我們授權的代理人、第三方供應商或外部供應商，以促進渠道所提供的服務；</li>
                            <li className="static-web-styling ol-li2-li">予監管、政府或司法機構以保護和捍衛我們的權利和財產；</li>
                            <li className="static-web-styling ol-li2-li">當我們認為根據適用的法律法規，包括但不限於司法程序、法院命令或渠道上的法律程序，而有必要提供予監管、政府或司法機構；</li>
                            <li className="static-web-styling ol-li2-li">當我們認為有必要以傳送路路香港供應的產品、維持和改善渠道的服務；及</li>
                            <li className="static-web-styling ol-li2-li">當我們在任何合併、融資、收購、解散、交易、訴訟、無力償債、破產或被接管的談判過程中出售、轉讓、剝離或披露其全部或部分業務或資產給另一家公司。這第三方將須要採取適當的保密和安全措施。</li>
                        </ol><br/>
                        <p>通過渠道所收集的個人資料可以在路路香港運作的任何國家內轉移、儲存或處理。一旦使用渠道，用戶將被視為同意並授權我們在上述情況下披露和轉載他/她/它的個人資料，以及將用戶資料（包括材料）轉載到其所在以外的其他國家。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Cookies和日誌檔</h2>
                        <p>Cookies是放置於用戶電腦或裝置上的小型文字檔案，包含一串可單獨識別用戶瀏覽器或裝置的字元。</p>
                        <p>當用戶進入渠道時，我們自動把諸如功能變數名稱伺服器地址、用戶訪問的頁面等資料記錄在cookies中。我們也自動把諸如互聯網協定地址、瀏覽器類型、互聯網服務供應商、進/退頁面、作業系統、日期/時間戳記和點擊流量等資料記錄在日誌檔中。我們不會把以上自動收集的數據與用戶任何的個人身份信息連接。</p>
                        <p>我們一般會使用cookies和日誌檔來了解渠道的使用方式、評估渠道和宣傳信息的受眾規模、幫助用戶有效地瀏覽頁面、幫助記住用戶的偏好設置及優化用戶使用渠道和服務的體驗。路路香港、路路香港聘請的第三方供應商及服務供應商有可能共同使用路路香港的cookies及第三方cookies來優化用戶在網上看到的營銷信息，並使營銷信息與用戶興趣更加相關。</p>
                        <p>用戶可以選擇接受或拒絕cookies。大多網絡瀏覽器都會自動接受cookies，但如果有需要，用戶可以修改其瀏覽器的設置以拒絕cookies。不過用戶應注意，禁用瀏覽器上的cookies可能會導致他/她/它無法充分體驗渠道的功能。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>第三方網站/媒體平台/應用程式的連結</h2>
                        <p>渠道可能會提供非由我們擁有或管理的第三方網站/媒體平台/應用程式的連結。當用戶決定點擊渠道上允許用戶進入一個第三方網站/媒體平台/應用程式的任何連結，他/她/它的個人資料可能會在這些第三方網站/媒體平台/應用程式中顯露和被收集。</p>
                        <p>在此要提醒用戶，此類第三方網站/媒體平台/應用程式履行個別和獨立的私隱政策，而本<strong>私隱政策</strong>並不會對用戶顯露於第三方網站/媒體平台/應用程式的個人資料提供保障。我們不會就用戶使用該等第三方網站/媒體平台/應用程式承擔任何責任或義務。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>安全</h2>
                        <p>我們遵循公認的行業標準和採用適當電子和管理方面的措施以保護用戶提交的個人資料免受未經授權的接觸、意外丟失或破壞。</p>
                        <p>所有用戶的個人資料由路路香港或路路香港授權的第三方之授權人員才可接觸，而當這些授權人員接觸此類個人資料時，會被指示須要遵守本<strong>私隱政策</strong>的條款。</p>
                        <p>註冊用戶有責任確保其密碼安全和機密。</p>
                        <p>儘管我們致力保護用戶的個人資料，沒有一種互聯網傳輸或電子儲存方法是完全安全的，所以我們亦不能保證其絕對安全。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>數據檢閱和更正的要求</h2>
                        <p>用戶可提出檢閱或更正由路路香港保留的其個人資料的要求，以保持此類信息準確。用戶可登錄賬戶維護頁面查看、編輯或修改已儲存的個人資料或透過列載於第12條“<strong>聯絡方法</strong>”的途徑聯絡我們的客戶服務代表，提出要求。</p>
                        <p>如用戶希望檢閱或更正他/她/它的個人資料，我們可能要求他/她/它提供某些個人信息以驗證他/她/它的身份。某些資料，包括但不限於商業登記證號碼，是無法修改的，除非這些資料證明是不準確的。</p>
                        <p>根據相關法律，我們有權就此要求收取合理的費用。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>數據的保留</h2>
                        <p>我們一經取得用戶的個人資料，資料將安全地保留在我們的系統中以履行列載於第2條“<strong>收集個人資料的目的</strong>”的目的。根據法律規定，其個人資料將會由路路香港保留，直至用戶透過列載於第12條“<strong>聯絡方法</strong>”的途徑聯絡我們的客戶服務代表終止其個人在渠道的會員資格。然而，如我們需要此類數據以履行相關法律義務和/或建立、行使或辯護索賠之用，我們有權保留此類數據。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>私隱政策的變更</h2>
                        <p>當我們認為有必要時，我們有權隨時更新、修改、修訂或更改本<strong>私隱政策</strong>。我們強烈建議用戶定期查看本<strong>私隱政策</strong>。若我們決定變更本<strong>私隱政策</strong>，我們將在此網頁和/或其他我們認為合適的地方發佈經修訂的<strong>私隱政策</strong>。若我們的<strong>私隱政策</strong>有重大更改，我們將在路路香港的網站上、手機應用程序上和/或透過電子郵件通知各用戶。</p>
                        <p>任何相關改動會在經修訂的<strong>私隱政策</strong>發佈在此網頁時立即生效。用戶於上述改動後繼續使用路路香港的渠道和服務即表示接受當時生效經修訂的<strong>私隱政策</strong>。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>主導語言</h2>
                        <p>如英文版本與其他語言的版本有任何抵觸或不相符之處，概以英文版本為準。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>聯絡方法</h2>
                        <p>如您對本<strong>私隱政策</strong>有任何疑問，請透過以下途徑聯絡我們的客戶服務代表：</p>
                        <address><strong>路路香港科技有限公司</strong><br/>
                        電郵地址：<a href = "mailto: contact@luluhk.com">contact@luluhk.com</a></address>
                    </li>
                </ol>
            </div>
        </div>
        
        <nav className="static-web-styling navbar navbar-expand navbar-light bg-light border-top border-dark fixed-bottom" id="bottomnav">
            <div className="static-web-styling collapse navbar-collapse">
                <ul className="static-web-styling navbar-nav mr-auto">
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/en/privacy" lang="en" hrefLang="en" onClick={()=>changeLanguage("en")}>EN</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link active" href="/tc/privacy" onClick={()=>changeLanguage("zh_Hant")}>繁</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/sc/privacy" lang="zh-Hans" hrefLang="zh-Hans" onClick={()=>changeLanguage("zh_Hans")}>简</a></li>
                </ul>
                <span className="static-web-styling copyright">&copy; 2025 路路香港科技有限公司</span>
            </div>
        </nav>  
            </div>
        )
    }
}

export default PrivacyWebTc;
