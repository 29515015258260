import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import Header from  "../components/item/Header";
import AboutLuluHK from "../components/setting/AboutLuluHK";
import { Link } from "react-router-dom";
import Language from "../components/setting/Language";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {changeLanguage} from "../utils/utils"
import WarningDialog from "../components/WarningDialog";

class Setting extends React.Component{
    constructor(props){
        super(props)
        this.goBack = this.goBack.bind(this); //to be deleted in full web
        this.state = {
            downloadOurAppOpen: false,
        };
        if(localStorage.getItem("language")){
            changeLanguage(localStorage.getItem("language"));
        }
    }
    componentDidMount() { 

    } 
    mobileContentClose = (e) => {
        e.currentTarget.parentElement.parentElement.classList.add('hide');
        document.querySelector(".wrapper .mobile-content-div").classList.add('hide');
        document.querySelector('.wrapper .padding_1').classList.remove("hide");
    }
    changeSettingContent = (e) => {
        document.querySelector(".setting-nav div.active").classList.remove('active')
        e.currentTarget.classList.add("active")
        document.querySelector(".setting-data div.active").classList.remove('active')
        document.getElementById(e.currentTarget.dataset.href).classList.add("active")
        if (e.currentTarget.dataset.href === "merchant-signup"){
            document.querySelector(".wrapper .content .setting-block").style.height="82%";
        }else{
            document.querySelector(".wrapper .content .setting-block").style.height="65%";
        }
    }
    settingListClick(e){
        document.querySelector(".wrapper .mobile-content-div").classList.remove('hide');
        document.getElementById(e.currentTarget.dataset.href).classList.remove("hide");   
    }
    mobileListClose(e){
        e.currentTarget.parentElement.parentElement.classList.add('hide');
        document.querySelector(".wrapper .mobile-setting-tab-content").classList.add('hide');
    }
    
    MobileShopItem = (props) => {
        return(
            <Link to="/order-plan">
                <div className="mobile-order-item">
                    <div className="shop-img">
                        <img src={ props.imgSrc } alt={ props.imgAlt } className="round-img"/>
                    </div>
                    <div className="item-data">
                        <span className="title text-main-color">{props.name}</span>
                        <span className="order-number">Order Number: {props.orderNumber}</span>
                        <span className="price-date">{props.currency + " " + props.price + " ‧ " + props.date}</span>
                    </div>
                </div>
            </Link>
        )
    }

    //to be deleted in full web
    goBack(){
        try {
            this.props.history.goBack();
        } catch (_) {
            window.location.replace("https://www.luluhk.com");
        }
    }

    render(){
        const {t, i18n} = this.props;
        let termsWebsiteLanguage;
        switch(i18n.language){
            case "zh_Hans":
                termsWebsiteLanguage="sc";
                break;
            case "zh_Hant":
                termsWebsiteLanguage="tc";
                break;
            default:
                termsWebsiteLanguage="en";
                break;
        }
        return(
            <React.Fragment>
                <Helmet>
                    <title>路路香港 LULU HK</title>
                    <meta property="og:title" content="路路香港 LULU HK" />
                    <meta property="og:description" content="香港 Hong Kong | 泊車指南 Parking Directory" />
                    <meta property="og:image" content="/img/luluhk/luluhk_og.png" />
                    <meta name="description" content="路路香港 LULU HK | 香港泊車指南 A Parking Directory in Hong Kong"/>
                </Helmet>
                <div className="wrapper">
                    <Header></Header>
                    <div className="mobile-setting-content ">
                        <div className="mobile-nav">
                            {/*to be deleted in full web*/}
                            <div className="search-back" onClick={this.goBack}><ArrowBackIosIcon /></div>
                            <span className="text-main-color title">{t("setting")}</span>
                            <div></div>
                        </div>
                        <div className="setting-content">
                            <div className="setting-list-item language" onClick={this.settingListClick} data-href="mobile-language-content">
                                <div className="item-info">
                                    <div className="list-icon">
                                        <span className="icon LuluHK lang"></span>
                                    </div>
                                    <div className="list-title">
                                        <span className="title">{t("language")}</span>
                                    </div>
                                </div>
                                <div className="arrow-icon">
                                    <span>{t("currentLanguageFull")}</span>
                                    <ArrowForwardIosIcon/>
                                </div>
                            </div>
                            <div className="setting-list-item merchant-signup" onClick={(e)=>{window.open("https://forms.gle/SwftJrmMqMwycqzv5").focus()}} data-href="mobile-contact-us">
                                <div className="item-info">
                                    <div className="list-icon">
                                        <span className="icon LuluHK merchant"></span>
                                    </div>
                                    <div className="list-title">
                                        <span className="title">{t("contactUs")}</span>
                                    </div>
                                </div>
                                <div className="arrow-icon">
                                    <ArrowForwardIosIcon/>
                                </div>
                            </div>
                            <span className="about"  onClick={this.settingListClick} data-href="mobile-about-content">{t("ui007001About")}</span>
                        </div>
                    </div>
                    <div className="mobile-content-div mobile-setting-tab-content hide">
                        {/* <div className="mobile-content hide mobile-planned-orders-content" id="mobile-planned-orders-content">
                            <div className="mobile-nav">
                                <div className="search-back" onClick={this.mobileListClose}><ArrowBackIosIcon /></div>
                                <span className="text-main-color title">Planned Orders</span>
                                <span className="icon LuluHK exclamation mark"></span>
                            </div>
                            <div className="planned-orders-content">
                                <this.MobileShopItem imgSrc="img/starbucks.png" imgAlt="Yi-feng pharmacy 39" name="Yi-feng pharmacy 39" orderNumber="545349" currency="HKD" price="188.09" date="Apr 14, 2025 01:27"/>
                                <this.MobileShopItem imgSrc="img/starbucks.png" imgAlt="Yi-feng pharmacy 39" name="Yi-feng pharmacy 39" orderNumber="703982" currency="HKD" price="188.09" date="Apr 14, 2025 01:04"/>
                            </div>
                        </div>
                        <div className="mobile-content hide mobile-contact-info-content" id="mobile-contact-info-content">
                            <div className="mobile-nav">
                                <div className="search-back" onClick={this.mobileListClose}><ArrowBackIosIcon /></div>
                                <span className="text-main-color title">Contact Info</span>
                                <div></div>
                            </div>
                            <div className="contact-info-content">
                                <div className="name-data data">
                                    <label for="name">Name(Optional)</label>
                                    <input className="name" type="text" value="le"></input>
                                    <span className="letter">2/30</span>
                                </div>
                                <div className="phone-data data">
                                    <label for="phone">Phone(Optional)</label>
                                    <input className="phone" type="text" value="+852 68880000"></input>
                                </div>
                            </div>
                            <div className="contact-info-footer">
                                <span className="msg">Info is stored locally on your device</span>
                                <button className="save">SAVE</button>
                            </div>
                        </div> */}
                        <div className="mobile-content hide mobile-language-content" id="mobile-language-content">
                            <div className="mobile-nav">
                                <div className="search-back" onClick={this.mobileListClose}><ArrowBackIosIcon /></div>
                                <span className="text-main-color title">{t("language")}</span>
                                <div></div>
                            </div>
                            <div className="language-content">
                                {/* <div className="language-block">
                                    <input type="radio"  className="language" id="dl" name="mobile-language"/>
                                    <label for="dl">Device Language</label>
                                </div> */}
                                <div className="language-block" onClick={(e)=>changeLanguage("zh_Hant")}>
                                    <input type="radio" className="language" id="mtc" name="mobile-language" checked={i18n.language==="zh_Hant"}/>
                                    <label for="mtc">繁體中文</label>
                                </div>
                                <div className="language-block" onClick={(e)=>changeLanguage("zh_Hans")}>
                                    <input type="radio"  className="language" id="mcn" name="mobile-language" checked={i18n.language==="zh_Hans"}/>
                                    <label for="mcn">简体中文</label>
                                </div>
                                <div className="language-block" onClick={(e)=>changeLanguage("en")}>
                                    <input type="radio"  className="language" id="men" name="mobile-language" checked={i18n.language==="en"}/>
                                    <label for="men">English</label>
                                </div>
                            </div>
                        </div>
                        <div className="mobile-content hide mobile-merchant-content" id="mobile-merchant-content">
                            <div className="mobile-nav">
                                <div className="search-back" onClick={this.mobileListClose}><ArrowBackIosIcon /></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="merchant-content">
                                <span className="title text-main-color">MERCHANT SIGNUP</span>
                                <span className="prompt">Please fill in the information of the merchant and contact person. We will contact you shortly for further verification.</span>
                                <input type="text" className="name" placeholder="Name of the merchant"></input>
                                <div className="merchant-address">
                                    <span className="icon LuluHK location_line text-main-color"></span>
                                    <input type="text" className="address" placeholder="Address of the merchant"></input>
                                </div>
                                <input type="text" className="email" placeholder="Email of the contact person"></input>
                                <input type="text" className="phone" placeholder="Phone of the contact person (Optional)"></input>
                                <input type="text" className="code" placeholder="Referral code (Optional)"></input>
                                <button className="submit">SUBMIT</button>
                            </div>
                        </div>
                        <div className="mobile-content hide mobile-about-content" id="mobile-about-content">
                            <div className="mobile-nav">
                                <div className="search-back" onClick={this.mobileListClose}><ArrowBackIosIcon /></div>
                                <span className="text-main-color title">{t("ui007001About")}</span>
                                <div></div>
                            </div>
                            <div className="about-content">
                                <div className="about-block terms" onClick={(e)=>{window.open("https://luluhk.com/"+termsWebsiteLanguage+"/terms/").focus()}}>
                                    <span class="content">{t("ui007024TermsOfUse")}</span>
                                    <div className="arrow-icon">
                                        <ArrowForwardIosIcon/>
                                    </div>
                                </div>
                                <div className="about-block policy" onClick={(e)=>{window.open("https://luluhk.com/"+termsWebsiteLanguage+"/privacy/").focus()}}>
                                    <span class="content">{t("ui007024PrivacyPolicy")}</span>
                                    <div className="arrow-icon">
                                        <ArrowForwardIosIcon/>
                                    </div>
                                </div>
                                <div className="about-block check-update" onClick={(e)=>{this.setState({downloadOurAppOpen: true})}}>
                                    <span class="content">{t("downloadOurApp")}</span>
                                    <div className="arrow-icon">
                                        {/* <span className="version">1.0.3</span> */}
                                        <ArrowForwardIosIcon/>
                                    </div>
                                </div>
                                <WarningDialog open={ this.state.downloadOurAppOpen } onClose={()=>{this.setState({downloadOurAppOpen: false})}}>
                                </WarningDialog>
                            </div>
                        </div>
                    </div>
                    <div className="content plan-background">
                        <div className="setting-block">
                            <p className="setting-title">{t("setting")}</p>
                            <hr/>
                            <div className="setting-content">
                                <div className="setting-nav">
                                    <div className="nav-title active" onClick={ this.changeSettingContent } data-href="language">{t("language")}</div>
                                    <div className="nav-title" onClick={ (e)=>{window.open("https://forms.gle/SwftJrmMqMwycqzv5").focus()} } data-href="contact-us">{t("contactUs")}</div>
                                    <div className="nav-title" onClick={ this.changeSettingContent } data-href="about-luluhk">{t("ui007001About")}</div>
                                </div>
                                <div className="setting-data">
                                    <Language></Language>
                                    <AboutLuluHK termsWebsiteLanguage={termsWebsiteLanguage}></AboutLuluHK>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )

    };
}

export default withTranslation()(Setting);